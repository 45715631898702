



























import { computed, defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

export default defineComponent({
  components: {
    OVehicleDataForm: () => import('@/components/organisms/o-vehicle-data-form.vue'),
  },

  setup(_, { root }) {
    const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

    const vehicle = ref<any>(null)
    const vehicleId = ref<string>(root.$route.params.id)

    const state = reactive({
      loading: false,
      valid: true,
      error: null as null | string
    })

    const model = reactive({
      data: {} as any
    })

    const updateModel = () => {
      if (vehicle.value) {
        model.data = {
          registrationNumber: vehicle.value.details?.registrationNumber || undefined,
          firstRegistrationDate: vehicle.value.details?.firstRegistrationDate || undefined,
          registrationCertificateHolderName: vehicle.value.details?.registrationCertificateHolderName || undefined,
          registrationCertificateHolderNumber: vehicle.value.details?.registrationCertificateHolderNumber || undefined,
          registrationCertificateHolderAddress: vehicle.value.details?.registrationCertificateHolderAddress || undefined,
          registrationCertificateOwnerName: vehicle.value.details?.registrationCertificateOwnerName || undefined,
          registrationCertificateOwnerNumber: vehicle.value.details?.registrationCertificateOwnerNumber || undefined,
          registrationCertificateOwnerAddress: vehicle.value.details?.registrationCertificateOwnerAddress || undefined,
          vehicleBrand: vehicle.value.details?.vehicleBrand || undefined,
          vehicleModel: vehicle.value.details?.vehicleModel || undefined,
          vehicleType: vehicle.value.details?.vehicleType || undefined,
          vinNumber: vehicle.value.details?.vinNumber || undefined,
          maximumGrossVehicleWeight: parseFloat(vehicle.value.details?.maximumGrossVehicleWeight) || undefined,
          grossVehicleWeight: parseFloat(vehicle.value.details?.grossVehicleWeight) || undefined,
          grossVehicleCombinationWeight: parseFloat(vehicle.value.details?.grossVehicleCombinationWeight) || undefined,
          vehicleCurbWeight: parseFloat(vehicle.value.details?.vehicleCurbWeight) || undefined,
          registrationCertificateValidityPeriod: vehicle.value.details?.registrationCertificateValidityPeriod || undefined,
          registrationCertificateIssueDate: vehicle.value.details?.registrationCertificateIssueDate || undefined,
          vehicleCategory: vehicle.value.details?.vehicleCategory || undefined,
          approvalCertificateNumber: vehicle.value.details?.approvalCertificateNumber || undefined,
          axlesNumber: parseInt(vehicle.value.details?.axlesNumber) || undefined,
          maximumGrossTrailerWeightWithBrakes: parseFloat(vehicle.value.details?.maximumGrossTrailerWeightWithBrakes) || undefined,
          maximumGrossTrailerWeightWithoutBrakes: parseFloat(vehicle.value.details?.maximumGrossTrailerWeightWithoutBrakes) || undefined,
          engineCapacity: parseFloat(vehicle.value.details?.engineCapacity) || undefined,
          maximumEnginePower: parseFloat(vehicle.value.details?.maximumEnginePower) || undefined,
          fuelType: vehicle.value.details?.fuelType || undefined,
          powerToWeightRatio: parseFloat(vehicle.value.details?.powerToWeightRatio) || undefined,
          seatsNumber: parseInt(vehicle.value.details?.seatsNumber) || undefined,
          standingPlacesNumber: parseInt(vehicle.value.details?.standingPlacesNumber) || undefined,
          vehicleKind: vehicle.value.details?.vehicleKind || undefined,
          productionYear: parseInt(vehicle.value.details?.productionYear) || undefined,
          loadCapacity: parseFloat(vehicle.value.details?.loadCapacity) || undefined,
          maximumAxleLoad: parseFloat(vehicle.value.details?.maximumAxleLoad) || undefined,
          vehicleCardNumber: vehicle.value.details?.vehicleCardNumber || undefined
        }
      }
    }

    watch(() => vehicle.value, updateModel, { deep: true })

    const fetchVehicle = () => {
      state.loading = true

      axiosInstance
        .get(`vehicle/${vehicleId.value}`)
        .then(({ data }) => {
          vehicle.value = data.vehicle
        })
        .catch(console.log)
        .finally(() => state.loading = false)
    }

    onMounted(fetchVehicle)

    const updateVehicle = () => {
      const data = {
        registrationNumber: model.data.registrationNumber || undefined,
        firstRegistrationDate: model.data.firstRegistrationDate || undefined,
        registrationCertificateHolderName: model.data.registrationCertificateHolderName || undefined,
        registrationCertificateHolderNumber: model.data.registrationCertificateHolderNumber || undefined,
        registrationCertificateHolderAddress: model.data.registrationCertificateHolderAddress || undefined,
        registrationCertificateOwnerName: model.data.registrationCertificateOwnerName || undefined,
        registrationCertificateOwnerNumber: model.data.registrationCertificateOwnerNumber || undefined,
        registrationCertificateOwnerAddress: model.data.registrationCertificateOwnerAddress || undefined,
        vehicleBrand: model.data.vehicleBrand || undefined,
        vehicleModel: model.data.vehicleModel || undefined,
        vehicleType: model.data.vehicleType || undefined,
        vinNumber: model.data.vinNumber || undefined,
        maximumGrossVehicleWeight: parseFloat(model.data.maximumGrossVehicleWeight) || undefined,
        grossVehicleWeight: parseFloat(model.data.grossVehicleWeight) || undefined,
        grossVehicleCombinationWeight: parseFloat(model.data.grossVehicleCombinationWeight) || undefined,
        vehicleCurbWeight: parseFloat(model.data.vehicleCurbWeight) || undefined,
        registrationCertificateValidityPeriod: model.data.registrationCertificateValidityPeriod || undefined,
        registrationCertificateIssueDate: model.data.registrationCertificateIssueDate || undefined,
        vehicleCategory: model.data.vehicleCategory || undefined,
        approvalCertificateNumber: model.data.approvalCertificateNumber || undefined,
        axlesNumber: parseInt(model.data.axlesNumber) || undefined,
        maximumGrossTrailerWeightWithBrakes: parseFloat(model.data.maximumGrossTrailerWeightWithBrakes) || undefined,
        maximumGrossTrailerWeightWithoutBrakes: parseFloat(model.data.maximumGrossTrailerWeightWithoutBrakes) || undefined,
        engineCapacity: parseFloat(model.data.engineCapacity) || undefined,
        maximumEnginePower: parseFloat(model.data.maximumEnginePower) || undefined,
        fuelType: model.data.fuelType || undefined,
        powerToWeightRatio: parseFloat(model.data.powerToWeightRatio) || undefined,
        seatsNumber: parseInt(model.data.seatsNumber) || undefined,
        standingPlacesNumber: parseInt(model.data.standingPlacesNumber) || undefined,
        vehicleKind: model.data.vehicleKind || undefined,
        productionYear: parseInt(model.data.productionYear) || undefined,
        loadCapacity: parseFloat(model.data.loadCapacity) || undefined,
        maximumAxleLoad: parseFloat(model.data.maximumAxleLoad) || undefined,
        vehicleCardNumber: model.data.vehicleCardNumber || undefined
      }

      state.loading = true

      axiosInstance
        .put(`vehicle/${vehicleId.value}/details`, data)
        .then(fetchVehicle)
        .catch(console.log)
        .finally(() => state.loading = false)
    }

    const isFormValid = computed(() => {
      if (!model.data.registrationNumber) return false
      if (!model.data.vehicleBrand) return false
      if (!model.data.vehicleModel) return false
      if (!model.data.vinNumber) return false
      if (!model.data.productionYear) return false

      return true
    })

    const onSubmit = () => {
      if (isFormValid.value) {
        updateVehicle()
      } else {
        state.error = 'Wypełnij wymagane pola'
      }
    }

    return { vehicle, state, model, onSubmit }
  }
})
